"use client";

/**
 * v0 by Vercel.
 * @see https://v0.dev/t/w551mBSc4os
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useTStream } from "#/hooks/use-t-stream";
import { logError } from "#/lib/datadog";
import { Button } from "#/ui/button";
import { Card, CardContent, CardHeader } from "#/ui/card";
import { StreamLogo } from "#/ui/logo";
import { useRouter } from "next/navigation";
import { useEffect, useMemo } from "react";

export default function FallbackError(props: {
  error: Error;
  action?: {
    onClick: () => void;
    text: string;
  };
}) {
  const error = props?.error;
  const { t } = useTStream("common");
  const router = useRouter();

  const errorId = useMemo(() => `${crypto.randomUUID()?.split("-").pop()}`, []);
  useEffect(() => {
    logError(error, { errorId });
    console.error({ error });
  }, [errorId]);

  return (
    <div className="w-full h-[70vh] flex items-center justify-center">
      <Card className="w-full p-5 relative bg-secondary rounded-xl max-w-md mx-auto">
        <CardHeader className="flex flex-col items-center space-y-2">
          <StreamLogo />
          <h2 className="text-xl pt-4 text-secondary-foreground font-bold">
            {t("Something went wrong")}
          </h2>
          <p className="text-sm text-secondary-foreground leading-loose text-center md:text-base/relaxed dark:text-gray-400">
            {`We've been notified about this error and we'll fix it ASAP. Please try refreshing in the meantime.`}
          </p>
        </CardHeader>
        <CardContent className="flex flex-row justify-center gap-6">
          <div className="flex flex-col gap-1">
            <Button
              className="h-10 w-full justify-start"
              onClick={() => router.push("/logout")}
              variant="secondary-foreground"
            >
              <LogOutIcon className="mr-2" />
              {t("Logout")}
            </Button>
          </div>
          <div className="flex flex-col gap-1">
            <Button
              onClick={() => window.location.reload()}
              className="h-10 w-full justify-start"
              variant="secondary-foreground"
            >
              <RefreshCwIcon className="mr-2" />
              {t("Reload")}
            </Button>
          </div>
        </CardContent>
        <div className="bg-background rounded-bl-[5px] px-2 absolute text-xs top-0 right-0 text-center text-destructive font-bold py-1">
          ERROR: {errorId}
        </div>
      </Card>
    </div>
  );
}

function LogOutIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1="21" x2="9" y1="12" y2="12" />
    </svg>
  );
}

function RefreshCwIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8" />
      <path d="M21 3v5h-5" />
      <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16" />
      <path d="M8 16H3v5" />
    </svg>
  );
}
