import { getGlobalObject } from "#/utils/GetGlobalObject";

export const logError = (
  error: Error,
  info: { componentStack?: string; errorId: string },
) => {
  const RumGlobal = getGlobalObject<Window>().DD_RUM;
  if (RumGlobal) {
    RumGlobal.addError(error, {
      scope: info?.componentStack,
      errorId: info.errorId,
    });
  }
};
