import ClipLoader from "react-spinners/ClipLoader";
import { twMerge } from "tailwind-merge";

export default function Loader({
  fullscreen,
  color,
  size,
  className,
}: {
  color?: string;
  fullscreen?: boolean;
  size?: number;
  className?: string;
}) {
  if (fullscreen) {
    return (
      <div className="w-full h-[40vh] flex items-center justify-center">
        <ClipLoader
          color={color || "#27C499"}
          size={size || 85}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    <div className={twMerge(className)}>
      <ClipLoader
        color={color || "#27C499"}
        size={size || 22}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
