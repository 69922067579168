import { getCookie, setCookie } from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";

const COOKIE_OPTIONS = {
  expires: new Date("2038-01-19"),
  secure: true,
  sameSite: true,
};

const cookies = {
  get: (name: string) => getCookie(name),
  set: (key: string, data: any, options?: OptionsType) =>
    setCookie(key, data, { ...COOKIE_OPTIONS, ...(options || {}) }),
  options: {
    access_token: "access_token",
    refresh_token: "refresh_token",
    org_id: "org_id",
    merchant_id: "merchant_id",
    partner_id: "x-partner-id",
  },
};

export default cookies;
