"use client";
import type { RumPublicApi } from "@datadog/browser-rum-core";

declare global {
  interface Window {
    DD_RUM?: RumPublicApi & {
      startView?(name?: string): void;
    };
  }
}

import FallbackError from "#/components/layout/fallback-error";
import "#/styles/custom-skeleton.css";
import "#/styles/globals.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "react-dates/initialize";
import { ErrorBoundary } from "react-error-boundary";
import "react-virtualized/styles.css";

import ToastContainer from "#/components/toast-container";
import { queryClient } from "#/lib/query";
import { ThemeContext } from "#/providers/theme-provider";
import { Provider } from "jotai";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { useContext } from "react";
import { IntercomProvider } from "react-use-intercom";

import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/react/utils";

const HydrateAtoms = ({ children }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

function App({ children }: any) {
  const theme = useContext(ThemeContext);
  return (
    <ErrorBoundary FallbackComponent={FallbackError}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <HydrateAtoms>
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer />
            <IntercomProvider appId="c8svb7mj">{children}</IntercomProvider>
            <ProgressBar
              height="2px"
              color={theme?.primaryColor}
              options={{ showSpinner: true }}
              shallowRouting
            />
          </HydrateAtoms>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
