import { LanguageContext } from "#/providers/language-provider";
import { useContext } from "react";
import cy from "../app/[site]/site/[lang]/dictionaries/cy.json";
import en from "../app/[site]/site/[lang]/dictionaries/en.json";
import es from "../app/[site]/site/[lang]/dictionaries/es.json";

type Language = "en" | "cy" | "es";
type Dictionaries = {
  en: typeof en;
  cy: typeof cy;
  es: typeof es;
};

type NestedKeys<T, Prefix extends string = ""> = {
  [K in keyof T & string]: T[K] extends object
    ? NestedKeys<T[K], `${Prefix}${K}.`>
    : `${Prefix}${K}`;
}[keyof T & string];

type CommonKeys = NestedKeys<Dictionaries[Language]>;

type ObjectPaths<T, Prefix extends string = ""> = {
  [K in keyof T & string]: T[K] extends object
    ? `${Prefix}${K}` | ObjectPaths<T[K], `${Prefix}${K}.`>
    : never;
}[keyof T & string];

type ValidPrefixes = ObjectPaths<Dictionaries[Language]>;

type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends `${infer T}${D}${infer Rest}`
    ? [T, ...Split<Rest, D>]
    : [S];

type GetSubKeys<T, P extends string[]> = P extends [infer First, ...infer Rest]
  ? First extends keyof T
    ? GetSubKeys<T[First & keyof T], Rest extends string[] ? Rest : []>
    : never
  : keyof T;

export function useTStream(): {
  t: (key: CommonKeys) => string;
};

export function useTStream<P extends ValidPrefixes>(
  prefix: P,
): {
  t: (
    key: GetSubKeys<Dictionaries[Language], Split<P, ".">>,
    body?: any,
  ) => string;
};

export function useTStream(prefix?: ValidPrefixes) {
  const theme = useContext(LanguageContext);

  return {
    t: (key: string, b?: any) => {
      const fullKey = `${prefix ? `${prefix}.` : ""}${key}`;

      // Split only at the first occurrence of a period if there's a prefix
      const [prefixPart, ...restParts] = fullKey.split(/\.(.+)/);
      const keyPart = restParts.join(""); // Join back the rest of the string

      // Use the entire key if there's no prefix, otherwise use the split parts
      const keys = prefix ? [prefixPart, keyPart].filter(Boolean) : [fullKey];

      const value = keys.reduce((acc, k) => acc?.[k], theme);

      if (b) {
        return Object.keys(b)?.reduce(
          (acc, k) => acc.replace(new RegExp(`{{${k}}}`, "g"), b[k]),
          value,
        );
      }

      return value;
    },
  };
}
