import { ThemeContext } from "#/providers/theme-provider";
import Image from "next/image";
import { useContext } from "react";

export function StreamLogo() {
  const theme = useContext(ThemeContext);

  return (
    <div className="relative h-7 w-28">
      <Image
        alt="Stream Logo"
        className="object-contain"
        fill
        src={theme.imageUrl}
      />
    </div>
  );
}
